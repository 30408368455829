<template>
  <div class="container">
    <div v-if="isAuthenticated" class="view-buttons">
      <div class="tab-navigation">
        <button @click="handleViewChange('recipes')" :class="{ active: currentView === 'recipes' }">Rezepte</button>
        <button @click="handleViewChange('storage')" :class="{ active: currentView === 'storage' }">Vorrat</button>
        <button @click="handleViewChange('dishes')" :class="{ active: currentView === 'dishes' }">Gerichte</button>
        <button @click="handleViewChange('list')" :class="{ active: currentView === 'list' }">
          <i class="bi bi-journal-check"></i>
        </button>
      </div>
    </div>

    <FilterComponent
      v-if="currentView === 'recipes'"
      :tags="tags"
      :methods="methods"
      @filter="applyFilter"
      @search="applySearch"
    />
   
    <StorageFilterComponent
      v-if="currentView === 'storage'"
      :tags="tags"
      @filter="applyFilter"
      @search="applySearch"
      @sort="applySort"
      @show-add-storage-modal="showAddStorageModal" 
    />

    <div v-if="currentView === 'storage'">
      <AddStorageItem 
        v-if="showAddStorageModalFlag" 
        @close-modal="showAddStorageModalFlag = false" 
        @itemAdded="handleStorageItemAdded" 
      />
    </div>

    <DishesFilterComponent
      v-if="currentView === 'dishes'"
      @show-add-dish-modal="showAddDishModal"
      @filter="applyFilter"
      @search="applySearch"
      @sort="applySort"
    />

    <div v-if="currentView === 'dishes'">
      <AddDishesItem v-if="showAddDishModalFlag" @close-modal="showAddDishModalFlag = false" @dish-added="addDish" />
    </div>
    <div class="row" v-if="currentView === 'recipes'">
      <div class="col-12 col-md-6 col-lg-4 mb-3" v-for="item in paginatedItems" :key="item.id">
        <ItemComponent 
          v-if="currentView === 'recipes'" 
          :item="item"
          @recipeAdded="handleRecipeItemAdded"
          @toDishes="toDishes"
          @removeDishes="removeDishes"
          :dishesItems="dishesItems"
        />

      </div>
    </div>

    <div class="col-12" v-for="item in paginatedItems" :key="item.id">
      <StorageItemComponent 
        v-if="currentView === 'storage'" 
        :item="item"
        @itemUpdated="updateItem"
        @itemDeleted="removeItem"
      /> 
    </div>

    <div v-if="currentView === 'dishes'">
      <DishesItemComponent 
        :dish="dish"
        :dishesItems="paginatedItems "
        :storageItems="storageItems"
        @update:dishesItems="updateDishes"
        @dishDeleted="removeItem"
        @toList="updateList"
      />
    </div>

    <div v-if="currentView === 'list'">
      <AddListItem 
        @itemAdded="handleListItemAdded" 
      />
      <div v-for="(item, index) in listItems" :key="index">
        <ListItemComponent
          :item="item"
          @itemUpdated="updateListItem"
          @itemDeleted="removeListItem"
          @toStorage="updateStorage"
        />
      </div>
    </div>
        
    <PaginationComponent
      v-if="totalPages > 1" 
      :currentPage="currentPage"
      :totalPages="totalPages"
      @page-changed="changePage"
    />
    
  </div>
</template>

<script>
import axios from 'axios';
import cache from '../store/cache.js';

import ItemComponent from '../components/ItemComponent.vue';
import FilterComponent from '../components/FilterComponent.vue';
import PaginationComponent from '../components/PaginationComponent.vue';
import { getCookie } from '../cookieUtils';
import { jwtDecode } from 'jwt-decode';

import StorageItemComponent from '../components/StorageItemComponent.vue';
import StorageFilterComponent from '../components/StorageFilterComponent.vue';
import AddStorageItem from './AddStorageItem.vue';

import DishesItemComponent from '../components/DishesItemComponent.vue';
import DishesFilterComponent from '../components/DishesFilterComponent.vue';
import AddDishesItem from './AddDishesItem.vue';

import ListItemComponent from '../components/ListItemComponent.vue';
import AddListItem from './AddListItem.vue';
import { useAuth } from '../store/auth';

export default {
  components: {
    ItemComponent,
    FilterComponent,
    PaginationComponent,
    StorageItemComponent,
    StorageFilterComponent,
    DishesItemComponent,
    DishesFilterComponent,
    AddDishesItem,
    AddStorageItem,
    ListItemComponent,
    AddListItem
  },
  setup() {
    const { isAuthenticated } = useAuth();
    
    return {
      isAuthenticated
    };
  },
  data() {
    return {
      items: [],
      filteredItems: [],
      storageItems: [],
      dishesItems: [],
      listItems: [],
      tags: ['Auflauf', 'Salat', 'Gemüse', 'Obst', 'Hähnchen', 'Schwein', 'Vegetarisch', 'Vegan', 'Schnell'],
      methods: ['Ofen', 'Heißluftfritteuse', 'Herd'],
      currentPage: 1,
      itemsPerPage: 8,
      searchQueries: [],
      selectedTags: [],
      currentView: 'recipes',
      isStorageView: false,
      selectedSort: 'newest',
      showAddDishModalFlag: false,
      showAddStorageModalFlag: false
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // Use a regular expression to match both '/add-recipe' and '/edit-recipe' with or without an ID
      if (/^\/(add-recipe|edit-recipe|mine)(\/\d+)?$/.test(from.path)) {
        vm.currentView = "recipes";
        cache.recipes = null; // Clear the cache
        vm.loadRecipes(); // Load recipes after clearing the cache
      }
    });
  },
  computed: {
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredItems.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },

  },
  watch: {
    filteredItems() {
      // Ensure currentPage is within valid range after filtering
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages;
      }
    },
    currentView(newView, oldView) {
      if (newView !== oldView) {
        this.currentPage = 1;
        this.loadData();
        }
    },
    $route(to, from) {
      if (to.query.view !== from.query.view) {
        this.currentView = to.query.view || this.currentView; // Use currentView if no query param
        this.loadData();
      }
    }
  },
  created() {
    const view = this.$route.query.view; // Remove the default value here
    if (view) {
      this.currentView = view;
    } else {
      this.currentView = 'recipes'; // Set to recipes only if no view is specified
    }
    this.loadData();
    this.checkAuth();
  },
  methods: {
    async handleViewChange(view) {
      this.searchQueries = [];
      this.currentView = view;
      this.currentPage = 1;
      await this.loadData();
      this.filterItems();
    },
    async handleListItemAdded(newItem) {
      this.listItems.unshift(newItem);
     
      cache.listItems = this.listItems;
      await this.loadListItems();
      this.filterItems();
    },
    async updateListItem(updatedItem) {
      // handle updating list item
      const index = this.listItems.findIndex(item => item.title === updatedItem.title);
        if (index !== -1) {
            // Update the specific item
            this.$set(this.listItems, index, updatedItem);
            cache.listItems = [...this.listItems]; // Update cache
            await this.loadListItems();
        }
    },
    async removeListItem(itemTitle) {
      // handle removing item from the list
      this.listItems = this.listItems.filter(item => item.title !== itemTitle);
      cache.listItems = this.listItems;
      await this.loadListItems();
      this.filterItems();
    },
    async toDishes(newDish) {
      try {
          // Add the new dish to the beginning of the dishesItems array
          this.dishesItems.unshift(newDish);

          // Update the cache to reflect the new state
          cache.dishesItems = this.dishesItems;

          // Reapply filters and pagination if necessary
          this.filterItems(); 
          this.paginateItems(); 
        } catch (error) {
          console.error('Error adding dish to dishes:', error);
      }
    },

    async removeDishes(dishTitle) {
      try {
        // Find the dish by title and remove it from the dishesItems array
        this.dishesItems = this.dishesItems.filter(item => item.title.toLowerCase() !== dishTitle.toLowerCase());

        // Update the cache to reflect the new state
        cache.dishesItems = this.dishesItems;

        // Reapply filters and pagination if necessary
        this.filterItems();
        this.paginateItems(); 
      } catch (error) {
        console.error('Error removing dish from dishes:', error);
      }
    },
    async updateStorage(item) {
      try {
        // Find the item in the local storageItems array
        const existingIndex = this.storageItems.findIndex(
          storageItem => storageItem.title.toLowerCase() === item.title.toLowerCase()
        );

        if (existingIndex !== -1) {
          // If the item already exists in storage, update its quantity
          this.storageItems[existingIndex].quantity += item.quantity;
        } else {
          // If it's a new item, add it to the beginning of the storageItems array
          this.storageItems = [item, ...this.storageItems]; // Spread existing items to ensure all items remain in the array
        }

        // Update the cache to reflect the new state
        cache.storageItems = [...this.storageItems]; // Ensure the cache is also updated with the complete array
        
        // Optional: Emit an event or update the UI as needed
        this.filterItems(); // Reapply filters if necessary
        this.paginateItems(); // Update the paginated view

      } catch (error) {
        console.error('Error updating storage:', error);
      }
    },
    async loadListItems() {
      this.itemsPerPage = 200;
      try {
        if (cache.listItems) {
          this.listItems = cache.listItems;
          this.filteredItems = this.listItems;
          return;
        }

        const token = getCookie('token');
        if (!token) {
          throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        const response = await axios.get(`/api/listItem/?id=${username}`);
        if (response.data && Array.isArray(response.data)) {
          this.listItems = response.data.reverse();
          cache.listItems = this.listItems;
          this.filteredItems = this.listItems;
        } else {
          console.error('Error: Expected an array of list items', response.data);
        }
      } catch (error) {
        console.error('Error loading list items:', error);
      }
    },
    countAvailableIngredients(ingredients) {
      if (!ingredients) return 0;

      let count = 0;
      
      ingredients.forEach(ingredient => {
        const foundInStorage = this.storageItems.find(storageItem => {
          return (
            storageItem.title.toLowerCase().trim() === ingredient.name.toLowerCase().trim() &&
            storageItem.quantity >= parseFloat(ingredient.amount)
          );
        });

        if (foundInStorage) {
          count++;
        }
      });

      return count;
    },
    showAddStorageModal() {
     
      this.showAddStorageModalFlag = true;
    },
    showAddDishModal() {
      this.showAddDishModalFlag = true;
    },
    changeView(view) {
      this.currentView = view;
      this.$router.push({ path: '/', query: { view: this.currentView } }); 
      this.loadData(); 
    },
    setView(view) {
      this.currentView = view;
      this.currentPage = 1;
      this.loadData(); // Assuming you have this method to load data based on the current view
      this.$router.push({ path: '/', query: { view: this.currentView } }); // Update URL if needed
    },
    checkAuth() {
      const token = getCookie('token');
      if (token) {
        const decodedToken = jwtDecode(token);
        if (decodedToken) {
          this.isAuthenticated = true;
        }
      }
    },
    async addDish(newDish) {

      newDish.amount = 1;

      // Combine amount and unit into a single string in the amount field
      newDish.ingredients = newDish.ingredients.map(ingredient => {
        // Combine the amount and unit into a single string
        ingredient.amount = `${ingredient.amount} ${ingredient.unit}`;

        // Remove the unit key from the ingredient object
        delete ingredient.unit;

        return ingredient;
      });


      // Push the new dish to the dishesItems array
      this.dishesItems.unshift(newDish); 

      cache.dishesItems = this.dishesItems; // Update the cache
      this.handleViewChange('dishes');

      const token = getCookie('token');
      if (!token) {
        throw new Error('Token not found');
      }
      const decodedToken = jwtDecode(token);
      const username = decodedToken.username;

      axios.post(`/api/dishesItem/?id=${username}`, newDish)
        .then(response => {
          console.log('Dish added successfully:', response.data);
        })
        .catch(error => {
          console.error('Error adding dish:', error);
        });
    },
    applyFilter(selectedTags) {
      this.selectedTags = selectedTags;
      this.filterItems();
      this.currentPage = 1;
    },
    async applySearch(searchQueries) {

      this.searchQueries = searchQueries.map(query => query.toLowerCase()); 
      this.currentPage = 1;

      await this.loadData();
    },
    applySort(sortOption) {
      this.selectedSort = sortOption;
      this.sortItems();
    
      this.currentPage = 1;
    },
    async updateDishes(updatedDishes) {
      this.dishesItems = updatedDishes;

      cache.dishesItems = this.dishesItems;
      this.filterItems();
      await this.loadDishesItems();
    },
    sortItems() {
      if (this.selectedSort === 'newest') {
        this.filteredItems.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));
      } else if (this.selectedSort === 'expirationAsc') {
        this.filteredItems.sort((a, b) => new Date(a.expiration) - new Date(b.expiration));
      } else if (this.selectedSort === 'expirationDesc') {
        this.filteredItems.sort((a, b) => new Date(b.expiration) - new Date(a.expiration));
      } else if (this.selectedSort === 'nameAsc') {
        this.filteredItems.sort((a, b) => a.title.localeCompare(b.title));
      } else if (this.selectedSort === 'nameDesc') {
        this.filteredItems.sort((a, b) => b.title.localeCompare(a.title));
      } else if (this.currentView === 'storage' && this.selectedSort === 'quantityAsc') {
        this.filteredItems.sort((a, b) => a.quantity - b.quantity);
      } else if (this.currentView === 'storage' && this.selectedSort === 'quantityDesc') {
        this.filteredItems.sort((a, b) => b.quantity - a.quantity);
      } else if (this.currentView === 'dishes' && this.selectedSort === 'quantityAsc') {
        this.filteredItems.sort((a, b) => a.amount - b.amount);
      } else if (this.currentView === 'dishes' && this.selectedSort === 'quantityDesc') {
        this.filteredItems.sort((a, b) => b.amount - a.amount);
      } else if (this.currentView === 'dishes' && this.selectedSort === 'ingredientsDesc') {
        this.filteredItems.sort((a, b) => {
          const aIngredientsAvailable = this.countAvailableIngredients(a.ingredients);
          const bIngredientsAvailable = this.countAvailableIngredients(b.ingredients);

          return bIngredientsAvailable - aIngredientsAvailable; // Sort descending
        });
      } 
    },
    filterItems() {
      let filtered;

      if (this.currentView === 'recipes') {
        filtered = this.items;
      } else if (this.currentView === 'storage') {
        filtered = this.storageItems;
        //filtered = this.storageItems.filter(item => item && item.title && item.quantity && item.quantityUnit); 
      } else if (this.currentView === 'dishes') {
        filtered = this.dishesItems.filter(dish => dish && dish.title); 
      } else {
        filtered = []; // Default case to avoid undefined issues
      }

      if (this.selectedTags.length > 0) {
        filtered = filtered.filter(item => {
          const itemTags = item.tags || [];
          const itemMethods = item.method || [];
          return this.selectedTags.every(tag => itemTags.includes(tag) || itemMethods.includes(tag));
        });
      }

      if (this.searchQueries.length > 0) {
        filtered = filtered.filter(item => {
          const itemTitle = item.title ? item.title.toLowerCase() : '';
          const itemIngredients = item.ingredients ? item.ingredients.map(ing => ing.name.toLowerCase()) : [];
          const itemMealType = item.mealType ? item.mealType.toLowerCase() : '';
          const username = item.username ? item.username.toLowerCase() : '';  // Add username to search

          return this.searchQueries.some(query => {
            return itemTitle.includes(query) ||
                  itemIngredients.some(ingredient => ingredient.includes(query)) ||
                  itemMealType.includes(query) ||
                  username.includes(query);  // Now search for username
          });
        });
        
      }
    this.filteredItems = filtered;
    this.sortItems(); // Ensure items are sorted after filtering
    this.paginateItems();
  },
  paginateItems() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    this.paginatedItems = this.filteredItems.slice(start, end);
  },
    changePage(page) {
      this.currentPage = page;
    },
    async handleStorageItemAdded(newItem){
      this.storageItems.unshift(newItem);

      cache.storageItems = this.storageItems;
      this.handleViewChange('storage')
    },
    handleRecipeItemAdded(){
      cache.recipes = this.recipes;
    },
    async updateItem({ oldTitle, updatedItem }){
      if(this.currentView === 'storage')
      {
        // Convert oldTitle to a string and normalize it
      const normalizedOldTitle = String(oldTitle).trim().toLowerCase();

      // Find the index using the old title
      const index = this.storageItems.findIndex(item => {
        // Convert item.title to a string and normalize it
        const normalizedItemTitle = String(item.title).trim().toLowerCase();
        return normalizedItemTitle === normalizedOldTitle;
      });

        if (index !== -1) {
          // Create a new array with the updated item to avoid mutating the original state
          this.storageItems = [
            ...this.storageItems.slice(0, index),
            updatedItem,
            ...this.storageItems.slice(index + 1)
          ];
          cache.storageItems = this.storageItems;
          await this.loadData();
          this.filterItems();
          this.paginateItems();
          //this.loadStorageItems();
        }
      }
    },
    async removeItem(itemEan) {
      if(this.currentView === 'storage')
      {
        this.storageItems = this.storageItems.filter(item => item.title !== itemEan);
        cache.storageItems = this.storageItems;
        this.filterItems();
        //await this.loadStorageItems();
      }
      else if(this.currentView === 'dishes')
      {
        this.dishesItems = this.dishesItems.filter(item => item.title !== itemEan);
        cache.dishesItems = this.dishesItems;
        this.filterItems();
        await this.loadStorageItems();
      }
    },
    async updateList() {
      cache.listItems = null;
      this.loadData;
    },
    async loadData() {
      if (this.currentView === 'recipes') {
        await this.loadRecipes();  
      } else if (this.currentView === 'storage') {
        await this.loadStorageItems();  
      } else if (this.currentView === 'dishes') {
        await this.loadDishesItems();  
      } else if (this.currentView === 'list') {
        await this.loadListItems();  
      }

      // Ensure that filteredItems are updated after loading the data
      this.filterItems();

    },
    async toggleView() {
      this.currentView = this.isStorageView ? 'storage' : 'recipes';
      this.loadData();
      this.$router.push({ path: '/', query: { view: this.currentView } });
      this.currentPage = 1;
    },
    async loadRecipes() {
      this.itemsPerPage = 8;
        try {
          // Check cache first
          if (cache.recipes) {
            this.items = cache.recipes;
            this.filteredItems = this.items;
            return;
          }

          // Fetch data from API
          const response = await axios.get('/api/recipes');
          const recipes = response.data.reverse();

          // Fetch reviews and calculate evaluation for each recipe
          const itemsWithEvaluation = await Promise.all(recipes.map(async item => {
            // Check cache for reviews
            
            if (!item || !item.id) {
              console.warn('Encountered a null or invalid item:', item);
              return null; // Skip null or invalid items
            }

            let reviews = cache.reviews[item.id];
            if (!reviews) {
              const reviewsResponse = await axios.get(`/api/reviews?id=${item.id}`);
              reviews = reviewsResponse.data;
              cache.reviews[item.id] = reviews; // Store reviews in cache
            }
            const evaluation = reviews.length > 0 ? (reviews.reduce((acc, review) => acc + review.rating, 0) / reviews.length) : 0;
            
            
            return {
              ...item,
              tags: item.tags != null ? item.tags : [], // Ensure tags is an array
              method: item.method != null ? item.method : [], // Ensure method is an array
              images: item.images != null ? item.images : [], // Ensure images is an array
              image: item.images && item.images.length > 0 ? item.images[0] : '', // Use the first image if available
              username: item.username,
              evaluation: evaluation
            };
          }));

          const validItems = itemsWithEvaluation.filter(item => item !== null);


          // Sort valid items by creation date in descending order
          this.items = validItems.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));


         // this.items = itemsWithEvaluation.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate)); // Sort by creation date in descending order
          this.filteredItems = this.items;

          // Cache the recipes
          cache.recipes = this.items;
        } catch (error) {
          console.error('Error loading recipes:', error);
        }
    },
    async loadStorageItems() {
      this.itemsPerPage = 200;
      try {
        // Check cache first
        if (cache.storageItems) {
          console.log("this.storageItems  " +this.storageItems );
          this.storageItems = cache.storageItems;
          this.filteredItems = this.storageItems; // If you're displaying storage items
          return;
        }

        const token = getCookie('token');
        if (!token) {
          throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        const response = await axios.get(`/api/storageItem/?id=${username}`);
        if (response.data && Array.isArray(response.data)) {
          const items = response.data.reverse();
          this.storageItems = items.map(item => ({
            ...item,
            tags: item.tags != null ? item.tags : [],
            expiration: item.expiration || ''
          }));

          cache.storageItems = this.storageItems;
          this.filteredItems = this.storageItems;
          
        } else {
          console.error('Error: Expected an array of storage items', response.data);
        }
      } catch (error) {
        console.error('Error loading storage items:', error);
      }
    },
   
    async loadDishesItems() {
    try {
      if (cache.dishesItems) {
        this.dishesItems = cache.dishesItems.filter(dish => dish && dish.title);
        this.filteredItems = this.dishesItems;
        return;
      }

      const token = getCookie('token');
      if (!token) {
        throw new Error('Token not found');
      }
      const decodedToken = jwtDecode(token);
      const username = decodedToken.username;

      const response = await axios.get(`/api/dishesItem/?id=${username}`);
      if (response.data && Array.isArray(response.data)) {
        this.dishesItems = response.data
          .reverse()
          .filter(dish => dish && dish.title); // Filter out empty items

        
        cache.dishesItems = this.dishesItems;
        this.filteredItems = this.dishesItems;

      } else {
        console.error('Error: Expected an array of dishes items', response.data);
      }
    } catch (error) {
      console.error('Error loading dishes items:', error);
    }
  }
  }
}
</script>

<style scoped>
.toggle-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.custom-switch {
  display: flex;
  align-items: center
}

.form-switch{
  padding-left: 0 !important;
}

.switch-container {
  display: flex;
  align-items: center;
}

.custom-switch .form-check-input {
  background-color: #ac8d7e;
  border-color: #ac8d7e;
  margin: 0 10px;
}

.custom-switch .form-check-input:checked {
  background-color: #ac8d7e;
  border-color: #ac8d7e;
}

.form-check-label.left-label {
  margin-right: 10px;
}

.form-check-label.right-label {
  margin-left: 10px;
}
.view-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.toggle-button {
  flex: 1;
  padding: 8px 15px;
  margin: 0 5px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 14px;
}

.toggle-button.active {
  background-color: #ac8d7e;
  color: white;
  border: 1px solid #ac8d7e;
  font-weight: bold;
}

.toggle-button:not(.active):hover {
  background-color: #e0e0e0;
}

@media (max-width: 768px) {
  .toggle-buttons {
    flex-direction: column;
  }
  
  .toggle-button {
    margin-bottom: 10px;
  }
}
.tab-navigation {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.tab-navigation button {
  flex: 1;
  padding: 10px 20px;
  background: none;
  border: none;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  transition: border-color 0.3s ease;
  color: black;
}

.tab-navigation button.active {
  border-bottom-color: #ac8d7e;
  font-weight: bold;
  color: black;
}

.tab-navigation button:hover:not(.active) {
  background-color: #f9f9f9;
}

.row{
  padding: 0 !important;
}
.list-icon{
  font-size: 24px; 
  padding: 5px;
}


</style>